import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Box, Flex, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import LinkArrowIcon from '../assets/link-arrows.svg';

const AngledInfoBlockTitle = styled(Box)(
    ({ theme }) => `
    position: relative;
    display: inline-block;
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeights.bold};
    padding: 5px 100px 5px 10px;
    overflow: hidden;
    color: ${theme.color.white};
    width: 80%;
    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme.color.black};
        -webkit-transform-origin: 0 100%;
        -ms-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: skew(45deg);
        -ms-transform: skew(45deg);
        transform: skew(45deg);
        z-index: -1;
    }
`
);

const StyledInfoBox = styled(Box)`
    flex: 1 2 475px;

    svg {
        display: inline-block;
        margin-left: 20px;
    }

    p {
        margin-bottom: 5px;
    }
`;

const StyledInfoBoxLink = styled(AnchorLink)(
    ({ theme }) => `
    display: block;
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.bold};
    margin-right: 60px;
    
    svg {
        margin-left: 10px;
        width: 16px;
    }

    &:hover {
        -moz-transition:color .2s ease-in;
        -o-transition:color .2s ease-in;
        -webkit-transition:color .2s ease-in;
        color: ${theme.color.brand};
    }
`
);

const InfoBlock = ({ icon, link, linkText, image, text, title }) => {
    return (
        <StyledInfoBox m={['0 0 40px 0', '0 80px 80px 0']} w={['100%', '50%']}>
            <Flex alignItems="center" mb="20px">
                <AngledInfoBlockTitle>{title}</AngledInfoBlockTitle>
                {icon}
            </Flex>
            {image}
            <Text mt="10px">{text}</Text>
            <StyledInfoBoxLink to={link}>
                <Flex alignItems="center">
                    {linkText}
                    <LinkArrowIcon />
                </Flex>
            </StyledInfoBoxLink>
        </StyledInfoBox>
    );
};

export default InfoBlock;
