import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import HeroImage from '../components/hero';
import InfoBlock from '../components/infoBlock';
import SectionFull from '../components/sectionFull';
import SectionFullImage from '../components/sectionFullImage';
import { Box, Button, Flex, Text, Stack } from '@chakra-ui/core';
import styled from '@emotion/styled';
import VLSLogoHero from '../assets/vls-logo-hero.svg';
import TarpingIcon from '../assets/tarping-icon.svg';
import LadderIcon from '../assets/ladder-man-icon.svg';
import DisasterIcon from '../assets/disaster-icon.svg';
import DroneIcon from '../assets/drone-icon.svg';

const StyledFlex = styled(Flex)`
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const StyledHeroContainer = styled(Box)`
    animation: fadein 2s;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const StyledVLSLogoContainer = styled(Flex)(
    ({ theme }) => `
        height: 600px;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: 500px;
        
        @media (max-width: ${theme.breakpoints[0]}) {
              height: 200px;
              width: 75%;
        }
    `
);

const IndexPage = () => {
    const [show, setShow] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            infoImageOne: file(relativePath: { eq: "home-ladder-assist-9354401.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            infoImageTwo: file(relativePath: { eq: "home-drone-297209746.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            infoImageThree: file(relativePath: { eq: "home-roof-tarping-18048332.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            infoImageFour: file(relativePath: { eq: "home-disaster-320091796.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const infoBlockContent = [
        {
            title: 'Ladder Assist',
            img: <Img fluid={data.infoImageOne.childImageSharp.fluid} />,
            text: 'On call and ready to help you UP!',
            icon: <LadderIcon />,
            link: '/services#ladder',
            linkText: 'LEARN MORE',
        },
        {
            title: 'Drone Surveys',
            img: <Img fluid={data.infoImageTwo.childImageSharp.fluid} />,
            text: 'Where no man can go when danger is lurking…',
            icon: <DroneIcon />,
            link: '/services#drone',
            linkText: 'LEARN MORE',
        },
        {
            title: 'Roof Tarping',
            img: <Img fluid={data.infoImageThree.childImageSharp.fluid} />,
            text: 'When the elements are at their worst…',
            icon: <TarpingIcon />,
            link: '/services#roof',
            linkText: 'LEARN MORE',
        },
        {
            title: 'Disaster Response',
            img: <Img fluid={data.infoImageFour.childImageSharp.fluid} />,
            text: 'When Disaster Strikes  and you need a team of professionals…',
            icon: <DisasterIcon />,
            link: '/services#disaster',
            linkText: 'LEARN MORE',
        },
    ];

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 5000);
    }, [show]);

    return (
        <Layout>
            <SEO title="Home" />
            {!show ? (
                <StyledVLSLogoContainer>
                    <VLSLogoHero />
                </StyledVLSLogoContainer>
            ) : (
                <StyledHeroContainer>
                    <HeroImage />
                </StyledHeroContainer>
            )}
            <Section>
                <StyledFlex>
                    {infoBlockContent.map((info, i) => (
                        <InfoBlock
                            key={i}
                            icon={info.icon}
                            link={info.link}
                            linkText={info.linkText}
                            image={info.img}
                            text={info.text}
                            title={info.title}
                        />
                    ))}
                </StyledFlex>
            </Section>
            <SectionFull>
                <Flex justifyContent="flex-start" direction={['column', 'row']} bg="gray.50">
                    <Box h={['300px', '600px']} w={['100%', '750px']} mr={['0', '40px']}>
                        <SectionFullImage />
                    </Box>
                    <Box m={['20px', '0']}>
                        <Stack spacing={0} mb="20px">
                            <Text fontSize="4xl" mt={['60px', '20px']}>
                                Welcome Home!!
                            </Text>
                        </Stack>
                        <Box w={['100%', '600px']}>
                            <Text mb="20px">
                                At Vertical Link Services LLC, we provide roof inspections, drone
                                surveys, roof tarping and disaster relief teams for the insurance
                                claims industry.
                            </Text>
                            <Text mb="20px">
                                Vertical Link Services proudly offers ladder assist services, roof
                                tarping, and more to all insurance adjusters, home inspectors, roof
                                inspectors, roofing contractors, and others needing access to a
                                homes roof or other structures.
                            </Text>
                            <Text mb="20px">
                                We are the vertical link between you and your customers. All of our
                                inspectors have a variety of backgrounds in roofing, construction,
                                claims adjusting as well as structural firefighting and damage
                                assessment.
                            </Text>
                            <Button
                                variant="ghost"
                                borderRadius="0"
                                border="2px"
                                size="lg"
                                _hover={{ bg: '#000', color: 'white' }}>
                                <Link to="/services">LEARN MORE</Link>
                            </Button>
                        </Box>
                    </Box>
                </Flex>
            </SectionFull>
            <SectionFull>
                <Flex
                    justifyContent="flex-start"
                    textAlign={['center', 'left']}
                    direction={['column', 'row']}
                    alignItems="center"
                    bg="gray.50"
                    h="200px">
                    <Box ml={['0', '80px']} mr={['0', '25px']}>
                        <Text fontSize="3xl">To schedule a service request call</Text>
                    </Box>
                    <Text fontSize="4xl" fontWeight="bold" mr={['0', '25px']}>
                        800.909.2711
                    </Text>
                    <Text display={['none', 'block']} fontSize="3xl">
                        or
                    </Text>
                    <Button
                        display={['none', 'block']}
                        variant="ghost"
                        borderRadius="0"
                        border="2px"
                        ml={['0', '25px']}
                        size="lg"
                        _hover={{ bg: '#000', color: 'white' }}>
                        <Link to="/claim">SCHEDULE A CLAIM</Link>
                    </Button>
                </Flex>
            </SectionFull>
        </Layout>
    );
};

export default IndexPage;
