import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/core';
import { graphql, Link, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import HeroGraphic from '../assets/hero-graphic.svg';
import styled from '@emotion/styled';

const BackgroundSection = ({ className }) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "hero-image-261672331.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
                <BackgroundImage Tag="section" className={className} fluid={imageData}>
                    <Flex direction="column" height="100%" justifyContent="center" width="100%">
                        <Box display="block" h={['80px', '125px', '140px']} w={['80%', '50%', '640px']} m="0 auto">
                            <HeroGraphic />
                        </Box>
                        <Button
                            alignSelf="center"
                            borderRadius="0"
                            border="2px"
                            color="white"
                            size="lg"
                            variant="ghost"
                            width="150px"
                            _hover={{ bg: '#000', color: 'white' }}>
                            <Link to="/services">LEARN MORE</Link>
                        </Button>
                    </Flex>
                </BackgroundImage>
            );
        }}
    />
);

const HeroImage = styled(BackgroundSection)(
    ({ theme }) => `
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 80px;
        height: 600px;
        text-align: center;
        width: 100%;
    
        svg {
            display: block;
            margin: 0 auto 60px auto;
        }
      
        @media (max-width: ${theme.breakpoints[0]}) {
          height: 300px;
        }
    `
);

export default HeroImage;
